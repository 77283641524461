@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: #333333;
    font-family: 'Montserrat', sans-serif;
}

span {
    cursor: pointer;
}

button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    background-color: transparent;
}

a {
    text-decoration: none;
}

svg {
    cursor: pointer;
}

.main:hover {
    fill: #999999;
}

.about:hover {
    fill: #999999;
}

#root {
    width: 100%;
    height: 100%;
    position: absolute;
}
